.customHtmlContentStyle p {
  font-size: 1rem;
  line-height: 2;
  margin-bottom: 1.5rem;
}

.customHtmlContentStyle img {
  max-width: 100%;
  height: auto;
  margin-bottom: 1.5rem;
}

.customHtmlContentStyle h1 {
  font-size: 1.8rem;
  line-height: 2;
  margin-bottom: 1.5rem;
}

.customHtmlContentStyle h2 {
  font-size: 1.5rem;
  line-height: 2;
  margin-bottom: 1.5rem;
}

.customHtmlContentStyle h3 {
  font-size: 1.25rem;
  line-height: 2;
  margin-bottom: 1.5rem;
}

.customHtmlContentStyle h4 {
  font-size: 1rem;
  line-height: 2;
  margin-bottom: 1.5rem;
}

.customHtmlContentStyle h5 {
  font-size: 0.875rem;
  line-height: 2;
  margin-bottom: 1.5rem;
}

.customHtmlContentStyle h6 {
  font-size: 0.75rem;
  line-height: 2;
  margin-bottom: 1.5rem;
}

.customHtmlContentStyle ul {
  margin-bottom: 1.5rem;
}

.customHtmlContentStyle ol {
  margin-bottom: 1.5rem;
}

.customHtmlContentStyle blockquote {
  background-color: blanchedalmond;
  font-family: Georgia, "Times New Roman", Times, serif;
  padding: 1.5rem;
  border-radius: 1rem;
}

.customHtmlContentStyle pre {
  margin-bottom: 1.5rem;
}

.customHtmlContentStyle code {
  margin-bottom: 1.5rem;
}

.customHtmlContentStyle table {
  margin-bottom: 1.5rem;
}

.customHtmlContentStyle th {
  margin-bottom: 1.5rem;
}

.customHtmlContentStyle td {
  margin-bottom: 1.5rem;
}

.customHtmlContentStyle a {
  color: #007bff;
  text-decoration: none;
}

.customHtmlContentStyle a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.customHtmlContentStyle iframe {
  width: 100%;
}

@media (min-width: 768px) {
  /* Adjust the min-width as needed for desktop breakpoint */
  .customHtmlContentStyle iframe {
    width: 40%;
  }
}
