@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }

    100% {
        background-position: 1000px 0;
    }
}

.shimmerText {
    background: linear-gradient(to right, #888888 0%, #222222 50%, #444444 100%);
    background-size: 2000px 100%;
    animation: shimmer 6s linear infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}