@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  @apply font-sans antialiased;
  margin: 0;
}

code {
  @apply font-mono;
}
